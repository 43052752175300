import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Box } from "@twilio-paste/core/box";
// import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";

import { sessionDataHandler } from "../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { Header } from "./Header";
import { notifications } from "../notifications";
import { NotificationBar } from "./NotificationBar";
import { introStyles, fieldStyles, titleStyles, formStyles, buttonStyles } from "./styles/PreEngagementFormPhase.styles";

export const PreEngagementFormPhaseA = () => {
    const { name, email, phoneNumber, query, caseDetails } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const { firstName, lastName } = JSON.parse(caseDetails ?? "");
    const dispatch = useDispatch();
    const url = new URL(window.location.href);

    const allParams = {
        ...Object.fromEntries(url.searchParams)
    };

    const urlParamsJson = JSON.stringify(allParams);
  
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name ? name : `${firstName} ${lastName}`,
                    email,
                    phoneNumber,
                    query,
                    caseDetails,
                    site: window.location.href,
                    urlParams: urlParamsJson
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementFormA }));
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Hi {firstName ?? "Again"}!
                </Text>
                <Text {...introStyles} as="p">
                    We&#39;re here to help. Please enter either your email or phone number to confirm your identity.
                </Text>
                {firstName ?
                    null
                    :
                    <Box {...fieldStyles}>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            type="text"
                            placeholder="Please enter your name"
                            name="name"
                            data-test="pre-engagement-chat-form-name-input"
                            value={name}
                            onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                            required
                        />
                    </Box>
                }
                <Box {...fieldStyles}>
                    <Label htmlFor="email">Confirm Email address</Label>
                    <Input
                        type="email"
                        placeholder="Please enter your email address"
                        name="email"
                        data-test="pre-engagement-chat-form-email-input"
                        value={email}
                        onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                        required={!phoneNumber}
                    />
                </Box>
                <Box {...fieldStyles}>
                    <Label htmlFor="phoneNumber">Confirm Phone Number</Label>
                    <Input
                        type="text"
                        placeholder="Please enter your phone number"
                        name="phoneNumber"
                        // data-test="pre-engagement-chat-form-email-input"
                        value={phoneNumber}
                        onChange={(e) => dispatch(updatePreEngagementData({ phoneNumber: e.target.value }))}
                        required={!email}
                    />
                </Box>
                {/* <Box {...fieldStyles}>
                    <Label htmlFor="query">How can we help you?</Label>
                    <TextArea
                        placeholder="Ask a question"
                        name="query"
                        data-test="pre-engagement-chat-form-query-textarea"
                        value={query}
                        onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                        onKeyPress={handleKeyPress}
                        required
                    />
                </Box> */}
                <Box
                    as="button"
                    variant="primary"
                    type="submit"
                    data-test="pre-engagement-start-chat-button"
                    {...buttonStyles}
                >
                    Start chat
                </Box>
            </Box>
        </>
    );
};
