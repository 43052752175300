import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

//type BackgroundColor = "testimonialColour" | "colorBackground" | "anotherColor";


export const outerContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "space40"
};

export const bubbleAndAvatarContainerStyles: BoxStyleProps = {
    display: "flex",
    alignItems: "flex-end"
};



export const getInnerContainerStyles = (): BoxStyleProps => ({
   
    paddingBottom: "space30",
 
    backgroundColor: "rgb(245, 243, 243)" as  "colorBackgroundPrimary",
    color:  "colorText" ,
    borderRadius: "borderRadius30",
    marginLeft:  "auto" ,
    marginRight:   "auto",
    maxWidth: "90%",
    minWidth: "90%"
});

export const getHeaderStyles = (): BoxStyleProps => ({
   
    backgroundColor: "#81817F" as  "colorBackgroundPrimary",
    color:  "colorText" ,
    borderRadius: "borderRadius30",
    padding:"space30",
    marginLeft:  "auto" ,
    marginRight:   "auto",
    marginBottom: "space10",
    maxWidth: "100%"
});

export const getBodyStyles = (): BoxStyleProps => ({
   
    paddingLeft: "space40",
    paddingRight: "space40"
});

export const authorStyles: TextStyleProps = {
    color: "inherit",
    fontWeight: "fontWeightBold",
    fontSize: "fontSize20",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis", // doesn't get applied for some reason – need to investigate
    overflow: "hidden"
};


export const bodyStyles: TextStyleProps = {
    color: "inherit",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
};

export const readStatusStyles: TextStyleProps = {
    textAlign: "right",
    fontSize: "fontSize10",
    marginRight: "space20",
    color: "colorText"
};
