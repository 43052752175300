import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const formStyles: BoxStyleProps = {
    padding: "space40",
    paddingTop: "space80",
    overflow: "auto"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize70",
    marginBottom: "space60"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space70"
};

export const fieldStyles: BoxStyleProps = {
    marginBottom: "space70"
};

export const buttonStyles: BoxStyleProps = {
    border: "none",
    backgroundColor: "colorBackgroundPrimary",
    display: "flex",
    height: "40px",
    width: "85px",
    fontSize: "0.875rem" as "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px!important" as "borderRadiusCircle",
    fontFamily: "'Inter var experimental', 'Inter var', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif" as "fontFamilyCode",
    fontWeight: "fontWeightBold",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    padding: "0px!important" as "space0",
    _hover: {
        backgroundColor: "colorBackgroundPrimaryStronger"
    },
    _focusVisible: {
        backgroundColor: "colorBackgroundPrimaryStronger",
        boxShadow: "shadowFocus"
    }
};

